<template>
    <div v-if="name === 'logo'">
        <svg width="288" height="82" viewBox="0 0 288 82" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_479_330)">
        <path d="M35.0794 0.483373L34.8006 0L0 60.2775H17.1865L18.9386 57.2239H5.28887L34.8006 6.10723L60.7894 51.0888H64.3169L35.0794 0.483373Z" fill="#717171"/>
        <path d="M59.53 0L50.4394 15.7468L52.2055 18.8004L59.53 6.10723L89.0417 57.2239H37.4403L35.6882 60.2775H94.3305L59.53 0Z" fill="#717171"/>
        <path d="M73.1564 66.4034H69.629L76.6281 78.5295H17.6094L43.6354 33.4457L43.7237 33.2923L41.9437 30.2666L12.3205 81.5831H81.9216L73.1564 66.4034Z" fill="#717171"/>
        </g>
        <g clip-path="url(#clip1_479_330)">
        <path d="M196.982 20.0256C196.305 20.0256 195.656 20.2932 195.177 20.7695C194.699 21.2458 194.43 21.8918 194.43 22.5654C194.43 23.239 194.699 23.8851 195.177 24.3613C195.656 24.8377 196.305 25.1053 196.982 25.1053C197.315 25.1108 197.646 25.0461 197.953 24.9152C198.26 24.7844 198.535 24.5904 198.761 24.346C199.219 23.8643 199.474 23.2263 199.474 22.5632C199.474 21.9 199.219 21.262 198.761 20.7803C198.535 20.5365 198.26 20.3433 197.953 20.2132C197.646 20.0831 197.315 20.0191 196.982 20.0256Z" fill="#717171"/>
        <path d="M198.444 32.9326H195.465V64.6399H198.444V32.9326Z" fill="#717171"/>
        <path d="M231.436 32.3135C226.719 32.3135 223.087 34.0037 220.603 37.348V32.9371H217.625V64.6444H220.603V48.7546C220.603 44.1179 221.38 40.7691 222.91 38.8122C224.921 36.426 227.74 35.1968 231.3 35.1968C234.31 35.1968 236.652 36.1549 238.455 38.1298C240.157 39.9963 241.02 43.336 241.02 48.0722V64.6535H243.998V47.3717C243.998 42.2333 242.922 38.4597 240.82 36.1549C238.482 33.606 235.331 32.3135 231.436 32.3135Z" fill="#717171"/>
        <path d="M284.313 36.1549C281.993 33.606 278.842 32.3135 274.947 32.3135C270.23 32.3135 266.598 34.0037 264.114 37.348V32.9371H261.136V64.6444H264.114V48.7546C264.114 44.1179 264.891 40.7691 266.421 38.8122C268.432 36.426 271.251 35.1968 274.811 35.1968C277.821 35.1968 280.163 36.1549 281.966 38.1298C283.668 39.9963 284.531 43.336 284.531 48.0722V64.6535H287.509V47.3717C287.509 42.2378 286.433 38.4597 284.313 36.1549Z" fill="#717171"/>
        <path d="M120.605 32.3132C116.029 32.3132 112.147 33.8904 109.069 37.0042C105.991 40.118 104.433 44.0226 104.433 48.6187C104.433 53.3052 105.977 57.3138 109.046 60.4773C110.513 61.9949 112.273 63.2025 114.22 64.0272C116.167 64.852 118.262 65.2773 120.378 65.2773C122.494 65.2773 124.588 64.852 126.535 64.0272C128.483 63.2025 130.243 61.9949 131.71 60.4773C134.66 57.2979 136.302 53.1304 136.309 48.802C136.315 44.4737 134.687 40.3011 131.746 37.1126C130.335 35.5748 128.612 34.352 126.692 33.5247C124.772 32.6975 122.697 32.2846 120.605 32.3132ZM129.571 58.525C127.192 61.0694 124.123 62.3574 120.396 62.3574C116.669 62.3574 113.527 61.0694 111.148 58.525C108.769 55.9807 107.548 52.6499 107.548 48.6052C107.548 44.6689 108.783 41.415 111.212 38.9339C113.641 36.4528 116.796 35.1919 120.605 35.1919C122.288 35.1581 123.959 35.4829 125.505 36.1447C127.052 36.8065 128.438 37.7899 129.571 39.0288C131.96 41.5822 133.172 44.8497 133.172 48.7408C133.172 52.6319 131.96 55.9852 129.571 58.5386V58.525Z" fill="#717171"/>
        <path d="M123.86 16.3882L118.195 26.2777L120.672 27.6835L126.336 17.7939L123.86 16.3882Z" fill="#717171"/>
        <path d="M180.733 47.9366C180.733 47.557 180.66 46.6079 180.533 45.6498C180.046 42.0657 179.007 38.5779 177.45 35.3097C174.829 29.6901 170.732 24.8794 165.592 21.3858L167.653 17.7704L165.174 16.3604L163.177 19.8537C161.356 18.8026 159.456 17.8959 157.492 17.1422L156.979 16.9433L155.958 19.5962L156.471 19.7905C158.3 20.4879 160.07 21.3297 161.765 22.3077L159.495 26.235L161.973 27.6405L164.153 23.8307C168.433 26.8039 171.919 30.7722 174.309 35.3911C171.669 33.3279 168.398 32.2288 165.043 32.2773C160.471 32.2773 156.589 33.8545 153.511 36.9683C150.433 40.0821 148.875 43.9867 148.875 48.5829C148.875 53.2693 150.419 57.2554 153.47 60.4324C154.937 61.95 156.697 63.1575 158.644 63.9823C160.591 64.8071 162.686 65.2323 164.802 65.2323C166.918 65.2323 169.012 64.8071 170.96 63.9823C172.907 63.1575 174.666 61.95 176.134 60.4324C177.637 58.8773 178.816 57.042 179.605 55.0317C180.394 53.0215 180.775 50.876 180.728 48.7184C180.728 48.4744 180.728 48.2303 180.728 47.9773L180.733 47.9366ZM174.036 58.5388C171.657 61.0832 168.588 62.3712 164.861 62.3712C161.134 62.3712 157.992 61.0832 155.613 58.5388C153.234 55.9945 152.013 52.6638 152.013 48.619C152.013 44.6827 153.243 41.4288 155.672 38.9477C158.101 36.4666 161.261 35.2058 165.065 35.2058C166.749 35.1712 168.421 35.4958 169.968 36.1576C171.515 36.8193 172.903 37.8031 174.036 39.0426C176.424 41.596 177.637 44.8635 177.637 48.7546C177.637 52.6457 176.424 55.9854 174.036 58.5388Z" fill="#717171"/>
        </g>
        <defs>
        <clipPath id="clip0_479_330">
        <rect width="94.3445" height="82" fill="white"/>
        </clipPath>
        <clipPath id="clip1_479_330">
        <rect width="182.963" height="48.9267" fill="white" transform="translate(104.433 16.4001)"/>
        </clipPath>
        </defs>
        </svg>
    </div>
    <span v-if="name === 'error'">
        <svg width="16" height="15" viewBox="0 0 16 15" fill="d74a41" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5942 10.1476L10.4995 1.43824C9.988 0.551551 9.03469 0.00120882 8.00371 1.98938e-06C6.97263 -0.00120497 6.01898 0.546866 5.50114 1.43828L0.404559 10.1508C-0.123921 11.0394 -0.135877 12.1529 0.376015 13.061C0.888332 13.9698 1.84761 14.5354 2.89104 14.5439L13.1002 14.544C14.1545 14.5337 15.1119 13.9698 15.6242 13.0618C16.1358 12.1548 16.1244 11.0438 15.5942 10.1476ZM1.65742 10.8897L6.75777 2.1708C7.01607 1.72618 7.4898 1.45392 8.00205 1.45452C8.51424 1.45512 8.9878 1.7285 9.24182 2.16885L14.3405 10.8851C14.6078 11.3369 14.6135 11.8931 14.3574 12.3472C14.1008 12.8019 13.6213 13.0843 13.0931 13.0895L2.89699 13.0894C2.3802 13.0852 1.8997 12.8019 1.64313 12.3467C1.38684 11.8921 1.39283 11.3347 1.65742 10.8897ZM8.00079 11.6349C8.40258 11.6349 8.72829 11.3093 8.72829 10.9076C8.72829 10.506 8.40258 10.1804 8.00079 10.1804C7.59901 10.1804 7.27329 10.506 7.27329 10.9076C7.27329 11.3093 7.59901 11.6349 8.00079 11.6349ZM8.73042 4.36231H7.27543V9.45312H8.73042V4.36231Z" fill="#d74a41"/>
        </svg>
    </span>
    </template>
<script>
export default {
	props: ['name'],
};
</script>